import { Icon } from '../icon';
import type { IconProps } from '../icon';

export function IconSocialTwitter(props: IconProps) {
	return (
		<Icon {...props}>
			<path
				fill="currentColor"
				d="M17.2 4.2h2.7L14 11l7.1 9.2h-5.6l-4.3-5.6-5 5.6H3.4L9.8 13 3 4.2h5.7l4 5.2 4.5-5.2Zm-1 14.4h1.5L8 5.8H6.2l10 12.8Z"
			/>
		</Icon>
	);
}

export function IconSocialFacebook(props: IconProps) {
	return (
		<Icon {...props}>
			<path
				d="M22 12.3a10 10 0 0 0-10-10 10 10 0 0 0-1.6 20v-7H8v-3h2.5v-2.2c0-2.5 1.5-4 3.8-4 1.1 0 2.3.3 2.3.3v2.4h-1.3c-1.3 0-1.6.8-1.6 1.6v1.9h2.7l-.4 3h-2.3v7a10 10 0 0 0 8.4-10Z"
				fill="currentColor"
			/>
		</Icon>
	);
}

export function IconSocialLinkedin(props: IconProps) {
	return (
		<Icon {...props}>
			<path
				fill="currentColor"
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M4.5 3.2C3.7 3.2 3 4 3 4.7v15c0 .9.7 1.5 1.5 1.5h15c.8 0 1.5-.6 1.5-1.5v-15c0-.8-.7-1.5-1.5-1.5h-15Zm4 4c0 1-.7 1.6-1.5 1.6S5.5 8 5.5 7.2c0-.8.6-1.5 1.5-1.5s1.5.7 1.5 1.5Zm3.8 2.8H9.8v8.6h2.6v-4.4l.1-.8c.2-.8 1-1.4 2-1.3.5.1.8.5 1 1l.1 1v4.5h2.7v-5l-.1-1.5a3 3 0 0 0-3-2.3h-.8a3.3 3.3 0 0 0-2.1 1.4V10Zm-6.6 8.6h2.6V10H5.7v8.6Z"
			/>
		</Icon>
	);
}
